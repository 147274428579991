$base64-png: 'image/png;base64';
$base64-jpg: 'image/jpeg;base64';
$base64-svg: 'image/svg+xml;base64';

// font var
//-----------------------------------------------------------------------------------
$default-font: Arial, 'Helvetica Neue', Helvetica, sans-serif;

// +font-face('font-icon', 'fonts/font-icon/font-icon', 'font-icon')

@include font-face('BebasNeueBold', '../fonts/BebasNeueBold/BebasNeueBold', 'BebasNeueBold', bold);
@include font-face('BebasNeueRegular', '../fonts/BebasNeueRegular/BebasNeueRegular', 'BebasNeueBold', 400);
//
//
// $proxima: 'Proxima Nova', $default-font

$Raleway: 'Roboto', $default-font;
$BebasNeueBold: 'BebasNeueBold', $default-font;
$BebasNeueRegular: 'BebasNeueRegular', $default-font;

// selection color
//-----------------------------------------------------------------------------------
$color-selection: dodgerblue;

// color var
//-----------------------------------------------------------------------------------
$color-link: dodgerblue;
