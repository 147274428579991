// seo text
//-----------------------------------------------------------------------------------

.seo-text {
    background-color: #f9f9fa;
    padding: 70px 0;
    text-align: right;
    @media (max-width: 767px) {
        padding: 40px 0; }
    p {
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #101211;
        margin-bottom: 16px;
        text-align: left;
        &:last-child {
            margin-bottom: 0; } } }
.hide-block {
    height: 100px;
    overflow: hidden;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(249,249,250,1) 100%); }
    &.is-active {
        height: auto;
        &:before {
            display: none; } } }
.show-text {
    display: inline-block;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e3b87;
    text-decoration: underline;
    margin-top: 10px;
    &:hover {
        text-decoration: none; } }
