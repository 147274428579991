// header
//-----------------------------------------------------------------------------------

.header-top-line {
    height: 41px;
    background-color: #f9f9fa;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        width: 300px;
        height: 100vh;
        z-index: 101;
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        padding-top: 20px;
        padding-bottom: 20px;
        transition: 0.3s;
        transform: translateX(100%);
        .content {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start; }
        &.is-active {
            transform: translateX(0); } } }
.header-mobile-left {
    @media (max-width: 767px) {
        display: block;
        overflow: auto;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #fff;
        width: 300px;
        height: 100vh;
        z-index: 101;
        box-sizing: border-box;
        padding: 20px 0;
        transition: 0.3s;
        transform: translateX(-100%);
        &.is-active {
            transform: translateX(0); } } }
.header-bottom-line {
    @media (max-width: 767px) {
        width: 100%;
        .content {
            flex-direction: column; }
        .container {
            box-sizing: border-box;
            width: 100%; } } }
.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; }
.header-top-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column; } }
.header-top-menu-link {
    display: block;
    opacity: 0.8;
    font-family: $Raleway;
    font-size: 13px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.54;
    letter-spacing: normal;
    color: #10151a;
    transition: 0.3s;
    margin: 0 15px;
    &:hover {
        text-decoration: none;
        color: #5e3b87;
        opacity: 1; }
    &:first-of-type {
        margin-left: 0; }
    &:last-of-type {
        margin-right: 0; }
    @media (max-width: 991px) {
        margin: 0 10px; }
    @media (max-width: 767px) {
        margin: 0 0 10px; } }
.header-phones-block {
    width: 161px;
    height: 49px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #efb31a;
    box-sizing: border-box;
    margin-bottom: -8px;
    padding: 7px 10px;
    position: relative;
    &:hover {
        border-radius: 0;
        .header-phones-list {
            display: block; }
        .icon-phone-arrow {
            transform: rotate(180deg); } }
    @media (max-width: 767px) {
        margin-bottom: 10px;
        height: auto; } }
.header-phones-list {
    display: none;
    position: absolute;
    left: 0;
    z-index: 2;
    top: 100%;
    width: 100%;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #f9f9fa;
    box-shadow: 0 2px 20px 0 #caccd1;
    padding: 15px 9px 14px 15px;
    @media (max-width: 767px) {
        display: block;
        position: static;
        top: auto;
        left: auto;
        background-color: transparent;
        padding: 0;
        box-shadow: none;
        margin-top: 5px; } }
.header-phones-item {
    font-family: Arial;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: #252525;
    display: block;
    margin-bottom: 19px;
    &:last-of-type {
        margin-bottom: 0; }
    &:hover {
        text-decoration: none; }
    @media (max-width: 767px) {
        color: #fff;
        margin-bottom: 5px; } }
.header-phones-text {
    font-family: $Raleway;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.4px;
    color: #ffffff; }
.header-phone-nember {
    font-family: Arial;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #ffffff;
    position: relative;
    &:hover {
        text-decoration: none; } }
.icon-phone-arrow {
    color: #fff;
    height: 3.4px;
    width: 6.3px;
    position: absolute;
    left: calc(100% + 22px);
    top: 50%;
    margin-top: -1.7px;
    transition: 0.3s;
    @media (max-width: 767px) {
        display: none; } }
.link-profile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #10151a;
    opacity: 0.8;
    transition: 0.3s;
    &:hover {
        text-decoration: none;
        color: #5e3b87;
        opacity: 1; } }
.icon-user {
    width: 12px;
    height: 16px;
    margin-right: 16px;
    @media (max-width: 991px) {
        margin-right: 0; } }
.icon-menu {
    width: 12px;
    height: 16px; }
.link-profile-text {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    margin-bottom: 0;
    @media (max-width: 991px) {
        display: none; }
    @media (max-width: 767px) {
        display: block;
        margin-left: 10px; } }
.header-mid-line {
    padding: 20px 0 30px 0;
    @media (max-width: 767px) {
        .content {
            flex-direction: column; } } }
.logo {
    width: 168px; }
.search-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; }
.search-input {
    width: 407px;
    height: 40px;
    border-radius: 3px;
    background-color: #fbfbfb;
    box-shadow: 0 1px 7px 0 #d5d6d8;
    border: 0;
    text-indent: 22.5px;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3e4247;
    &::placeholder {
        color: #868e96; }
    @media (max-width: 991px) {
        width: 230px; } }
.search-sbm {
    width: 40px;
    height: 40px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #5e3b87;
    color: #ffffff; }
.icon-search {
    width: 15.3px;
    height: 15.9px; }
.header-mid-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end; }
.header-control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #c2c2c2;
    padding-bottom: 24px;
    transition: 0.3s;
    height: 68.8px;
    box-sizing: border-box;
    margin: 0 14px -24px;
    position: relative;
    &:hover {
        text-decoration: none;
        color: #3e4247;
        cursor: pointer;
        .header-control-block {
            display: block; } }
    @media (max-width: 767px) {
        &:hover {
            .header-control-block {
                display: none; } } }
    &:first-of-type {
        margin-left: 0; }
    &:last-of-type {
        margin-right: 0; }
    &.is-active {
        color: #3e4247;
        .counter {
            opacity: 1; } } }
.icon-comparison {
    width: 37px;
    height: 29px; }
.header-control-text {
    font-family: $Raleway;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }
.icon-like {
    width: 33px;
    height: 29px; }
.icon-basket {
    width: 32px;
    height: 30.8px; }
.counter {
    transition: 0.3s;
    width: 16px;
    height: 16px;
    background-color: #5e3b87;
    text-align: center;
    font-family: $Raleway;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 16px;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: 10px;
    opacity: 0; }
.catalog-block {
    width: 208px;
    height: 51px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #efb31a;
    box-sizing: border-box;
    font-family: $Raleway;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 51px;
    letter-spacing: 0.6px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    @media (max-width: 767px) {
        width: 100%; } }
.catalog-list-block {
    display: none;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 940px;
    left: 0;
    top: 100%;
    box-sizing: border-box;
    background-color: #f9f9fa;
    box-shadow: 0 15px 25px 0 #caccd1;
    position: absolute;
    z-index: 3;
    @media (max-width: 991px) {
        width: 720px; }
    @media (max-width: 767px) {
        width: 100%;
        display: block;
        position: static;
        box-shadow: none;
        background-color: #fff; } }
.header-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column; } }
.header-nav-link {
    display: block;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e4247;
    margin: 0 45.4px;
    position: relative;
    &:first-of-type {
        margin-left: 0; }
    &:last-of-type {
        margin-right: 0; }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 100%;
        height: 2px;
        width: 0;
        transition: 0.3s;
        background-color: #efb31a; }
    &:hover {
        text-decoration: none;
        color: #5e3b87;
        &:before {
            width: 100%; } }
    @media (max-width: 991px) {
        margin: 0 20px; }
    @media (max-width: 767px) {
        margin: 0 0 10px; } }
.first-slider-item {
    height: 485px;
    overflow: hidden;
    @media (max-width: 991px) {
        height: 365px; }
    @media (max-width: 767px) {
        height: 200px; } }
.catalog-list-first-column {
    width: 210px;
    box-sizing: border-box;
    margin-right: 2px;
    margin-bottom: 3px;
    margin-top: 2px;
    @media (max-width: 767px) {
        width: 100%; } }
.catalog-first-list-item {
    display: block;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    font-family: $Raleway;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #3e4247;
    margin-bottom: 1px;
    border-left: 2px solid #f9f9fa;
    transition: 0.3s;
    padding-left: 6px;
    line-height: 36px;
    position: relative;
    &:hover {
        text-decoration: none;
        border-color: #efb31a;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.04);
        font-weight: 800; }
    &:last-of-type {
        margin-bottom: 0; }
    &.mod-submenu {
        &:before {
            content: '';
            position: absolute;
            background: url(../img/svg/catalog-arrow.svg);
            width: 3.6px;
            height: 6.3px;
            top: 50%;
            transform: translateY(-50%);
            right: 12px; } }
    @media (max-width: 767px) {
        border-color: #fff;
        &:hover {
            border-color: #efb31a; } } }
.catalog-container {
    position: relative;
    &:hover {
        .catalog-list-block {
            display: flex; } }
    @media (max-width: 767px) {
        margin-bottom: 10px;
        width: 100%; } }
.catalog-second-list-item {
    font-family: $Raleway;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: -0.1px;
    color: #3e4247;
    display: block;
    transition: 0.3s;
    background-color: #fff;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 288px;
    padding: 0 42px 0 38px;
    box-sizing: border-box;
    &:hover {
        text-decoration: none;
        font-weight: 800; } }
.catalog-list-second-column {
    background-color: #fff;
    width: 288px;
    box-sizing: border-box;
    margin-bottom: 3px;
    margin-top: 2px;
    @media (max-width: 767px) {
        display: none; } }
.catalog-list-third-column {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 8px;
    @media (max-width: 767px) {
        display: none; } }
.catalog-item-img {
    margin-left: auto;
    max-height: 100%;
    display: none; }
.catalog-item-submenu {
    display: none; }
.header-control-block {
    position: absolute;
    right: 0;
    top: 100%;
    padding-top: 24px;
    z-index: 2;
    display: none; }
.header-control-container {
    width: 357px;
    border-radius: 2px;
    background-color: #f9f9fa;
    box-shadow: 0 2px 20px 0 #caccd1;
    padding: 21px 5px 5px;
    box-sizing: border-box; }
.header-control-block-name {
    font-family: $Raleway;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232323;
    padding-left: 14px;
    margin-bottom: 20px; }
.header-control-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #ffffff;
    margin-bottom: 5px;
    position: relative;
    &:hover {
        .header-control-item-name {
            color: #5e3889; } } }
.header-control-img-block {
    height: 68px;
    width: 87px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px; }
.header-control-item-info {
    margin-left: 10px;
    margin-top: 13px; }
.header-control-item-name {
    width: 200px;
    font-family: $Raleway;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #252525;
    margin-bottom: 6px;
    transition: 0.3s; }
.header-control-item-cost {
    font-family: $Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #a5a7a9; }
.icon-remove {
    width: 6px;
    height: 6px;
    color: #000000;
    position: absolute;
    top: 18px;
    right: 15px;
    transition: 0.3s;
    &:hover {
        color: #c50b0b; } }
.header-control-total-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    margin-top: 11px;
    margin-bottom: 36px; }
.header-control-total-text {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a5a7a9; }
.header-control-total-cost {
    font-family: Arial;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525; }
.header-control-block-btn {
    display: block;
    width: 100%;
    height: 49px;
    border-radius: 2px;
    background-color: #5e3b87;
    line-height: 45px;
    text-align: center;
    box-sizing: border-box;
    font-family: Arial;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
    transition: 0.3s;
    border: 2px solid #5e3b87;
    margin-bottom: 2px;
    &:hover {
        text-decoration: none;
        color: #5e3b87;
        background-color: #f9f9fa; }
    &.mod-white {
        background-color: #f9f9fa;
        border-color: #f9f9fa;
        color: #5e3b87;
        margin-bottom: 0;
        &:hover {
            border-color: #5e3b87; } } }
.header-mobile {
    display: none;
    @media (max-width: 767px) {
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        background-color: #fff;
        height: 50px;
        width: 100%;
        box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.1);
        .content {
            align-items: center;
            flex-direction: row;
            height: 50px; }
        .logo-link {
            display: block;
            .logo {
                height: 40px;
                width: auto; } } } }
.logo-link {
    @media (max-width: 767px) {
        display: none; } }
.search-block {
    @media (max-width: 767px) {
        margin-bottom: 15px; } }
.close-right-menu {
    position: absolute;
    display: none;
    .icon-remove {
        width: 10px;
        height: 10px; }
    @media (max-width: 767px) {
        display: block;
        top: 10px;
        right: 10px;
        .icon-remove {
            position: static; } } }
.close-left-menu {
    position: absolute;
    display: none;
    .icon-remove {
        width: 10px;
        height: 10px; }
    @media (max-width: 767px) {
        display: block;
        top: 10px;
        right: 10px;
        .icon-remove {
            position: static; } } }
