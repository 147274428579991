// catalog page
//-----------------------------------------------------------------------------------

.catalog {
    padding-top: 20px;
    padding-bottom: 70px;
    .breadcrumbs-item {
        margin-right: 36px;
        margin-left: 0;
        &:first-child {
            margin-left: 0; }
        &:last-child {
            margin-right: 0; }
        &:before {
            left: calc(100% + 17px); }
        @media (max-width: 991px) {
            margin-right: 24px;
            margin-left: 0;
            &:before {
                left: calc(100% + 12px); } }
        @media (max-width: 767px) {
            margin: 0 0 5px;
            &:before {
                display: none; } } } }
.catalog-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start; }
    .breadcrumbs {
        margin-bottom: 0; } }
.catalog-head-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    @media (max-width: 767px) {
        margin-top: 20px; } }
.select {
    .ui.selection.dropdown {
        line-height: normal;
        min-width: 180px;
        min-height: 29px;
        border-radius: 2px;
        border: solid 1px #cccccc;
        box-sizing: border-box;
        padding: 4px 10px; }
    .ui.dropdown>.text {
        font-family: Arial;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.92;
        letter-spacing: normal;
        color: #868686; }
    .ui.dropdown>.dropdown.icon {
        font-size: 0;
        padding: 0;
        background: url(../img/svg/catalog-arrow-select.svg) center center no-repeat;
        width: 9px;
        height: 5px;
        display: block;
        right: 12px;
        top: 50%;
        margin-top: -2.5px;
        transition: 0.3s; }
    .ui.visible.selection.dropdown>.dropdown.icon {
        transform: rotate(180deg); }
    .ui.selection.active.dropdown:hover {
        border-color: #cccccc; }
    .ui.selection.active.dropdown:hover .menu {
        border-color: #ccc;
        box-sizing: border-box; }
    .ui.dropdown .menu>.item {
        font-family: Arial;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.92;
        letter-spacing: normal;
        color: #868686;
        padding: 4px 10px !important; }
    .ui.selection.active.dropdown .menu {
        box-sizing: border-box;
        border-color: #cccccc; } }
.select-label {
    font-family: Arial;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: #101211;
    margin-bottom: 5px; }
.visual-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 20px;
    @media (max-width: 991px) {
        display: none; } }
.visual-btn {
    width: 35px;
    height: 29px;
    border-radius: 2px;
    border: solid 1px #cccccc;
    color: #efb31a;
    transition: 0.3s;
    &:first-of-type {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    &:last-of-type {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
    &.is-active {
        background-color: #efb31a;
        border-color: #efb31a;
        color: #fff; } }
.icon-catalog-dots {
    width: 13px;
    height: 13px; }
.icon-catalog-list {
    width: 13px;
    height: 13px; }
.catalog-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; }
.filter {
    width: 220px;
    box-sizing: border-box;
    border-right: 1px solid #d8d8d8;
    padding-right: 14px;
    @media (max-width: 767px) {
        border-right: 0;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #fff;
        width: 300px;
        z-index: 100;
        height: 100vh;
        overflow: auto;
        padding: 20px;
        transition: 0.3s;
        transform: translateX(-100%); }
    &.is-active {
        @media (max-width: 767px) {
            transform: translateX(0); } } }
.filter-item {
    border-bottom: 1px solid #d8d8d8; }
.filter-item-text {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    cursor: pointer;
    padding: 11px 10px 11px 0;
    position: relative;
    margin-bottom: 0;
    &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        background: url(../img/svg/breadcrumbs-arrow.svg) center center no-repeat;
        width: 5px;
        height: 9px;
        transform: rotate(90deg) translateX(-50%);
        transition: 0.3s; } }
.filter-item-hidden {
    padding: 0 0 20px;
    box-sizing: border-box;
    display: none; }
.slider-range {
	width: 100%; }
.ui-slider {
	position: relative; }
.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 13px;
    height: 13px;
    background-color: rgba(239, 179, 26, 0.4);
    border-radius: 50%;
    cursor: pointer;
    &:before {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: #efb31a;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%; } }
.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    overflow: hidden; }
.ui-slider-horizontal {
    height: 3px; }
.ui-slider-horizontal .ui-slider-handle {
    top: -5px;
    margin-left: -6px; }
.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%; }
.ui-slider-horizontal .ui-slider-range-min {
    left: 0; }
.ui-slider-horizontal .ui-slider-range-max {
    right: 0; }
.ui-widget-content {
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    background-color: #e2e2e2; }
.ui-widget-header {
    height: 3px;
    border-radius: 1.5px;
    background-color: rgba(239, 179, 26, 0.6); }
.ui-corner-all {
    border-radius: 4px; }
.range-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; }
.range-text {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #101211;
    margin-bottom: 0; }
.range-input {
    width: 72px;
    height: 29px;
    border-radius: 2px;
    border: solid 1px #cccccc;
    box-sizing: border-box;
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #868686;
    text-indent: 10px; }
.checkbox-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0; } }
.checkbox {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    visibility: hidden;
    &:checked {
        &+.checkbox-icon {
            &:before {
                background-image: url(../img/svg/check.svg);
                background-position: center center;
                background-repeat: no-repeat; }
            .checkbox-text {
                color: #5e3b87; } } } }
.checkbox-icon {
    display: block;
    position: relative;
    padding-left: 20px;
    height: 20px;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: solid 1px #e4e4e4;
        background-color: #fbfbfb;
        background-position: center center;
        box-sizing: border-box;
        transition: 0.3s; } }
.checkbox-text {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: normal;
    color: #868686;
    transition: 0.3s;
    margin-left: 10px; }
.catalog-right {
    width: calc(100% - 220px);
    padding-left: 20px;
    @media (max-width: 767px) {
        width: 100%;
        padding-left: 0; } }
.catalog-conteiner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: 20px;
    &:after {
        content: "";
        flex: auto;
        @media (max-width: 991px) {
            display: none; } }
    @media (max-width: 767px) {
        justify-content: center; }
    &.mod-list {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .catalog-conteiner-item {
            width: 100%;
            margin: 0 0 20px;
            background-color: #fff;
            box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.2);
            box-sizing: border-box;
            padding: 10px;
            transition: 0s;
            &:hover {
                transform: translateY(0); } }
        .product-item-conteiner {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            transition: 0s;
            padding-bottom: 0; }
        .product-img-block {
            margin-bottom: 0;
            box-shadow: none; }
        .block-with-mod-list {
            width: calc(100% - 220px);
            padding: 20px 0 20px 10px;
            box-sizing: border-box; }
        .product-item-info {
            display: block; }
        .product-item-name {
            height: auto; }
        .product-swap-cotainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; }
        .product-item-btn {
            opacity: 1;
            margin: 0; }
        .product-cost-row {
            min-width: 200px;
            margin-bottom: 0; } } }
.catalog-conteiner-item {
    width: 240px;
    margin: 0 -10px;
    transition: 0.3s;
    &.mod-fix-margin {
        margin: 0 12px;
        @media (max-width: 991px) {
            margin: 0 -10px; }
        @media (max-width: 767px) {
            margin: 0 10px 20px; } }
    @media (max-width: 767px) {
        background-color: #fff;
        box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.2);
        border-radius: 2px;
        transform: translateY(-10px);
        margin: 0 10px 20px;
        .product-img-block {
            box-shadow: none; }
        .product-item-btn {
            opacity: 1; }
        .slick-prev {
            display: none; }
        .slick-next {
            display: none; } }
    .product-item-conteiner {
        transition: 0.3s; }
    .product-img-block {
        transition: 0.3s; }
    &:hover {
        background-color: #fff;
        box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.2);
        border-radius: 2px;
        transform: translateY(-10px);
        &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -10px;
            width: 100%;
            height: 10px; }
        .product-img-block {
            box-shadow: none; }
        .product-item-btn {
            opacity: 1; }
        .slick-prev {
            opacity: 1;
            &.slick-disabled {
                opacity: 0.3; } }
        .slick-next {
            opacity: 1;
            &.slick-disabled {
                opacity: 0.3; } } } }
.catalog-bottom {
    width: 100%;
    text-align: center; }
.load-more {
    display: inline-block;
    text-decoration: underline;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e3b87;
    margin-bottom: 43px;
    &:hover {
        text-decoration: none; } }
.pagination-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: cenetr; }
.pagination-item {
    display: block;
    margin: 0 2.5px; }
.pagination-link {
    transition: 0.3s;
    display: block;
    font-family: Arial;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 34px;
    letter-spacing: normal;
    color: #252525;
    width: 42px;
    height: 34px;
    border-radius: 3px;
    border: solid 1px #a5a7a9;
    box-sizing: border-box;
    &:hover {
        text-decoration: none;
        background-color: #5e3b87;
        border-color: #5e3b87;
        color: #fff; }
    &.is-active {
        background-color: #5e3b87;
        border-color: #5e3b87;
        color: #fff; } }
.product-img-block {
    .slick-prev {
        width: 14px;
        height: 24px;
        left: 0;
        z-index: 2;
        top: 50%;
        margin-top: -14px;
        transition: 0.3s;
        &:before {
            display: none; }
        .icon {
            transform: rotate(180deg); }
        &.slick-disabled {
            opacity: 0.3; } }
    .slick-next {
        width: 14px;
        height: 24px;
        right: 0;
        z-index: 2;
        top: 50%;
        margin-top: -14px;
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.3; } } }
.product-item-info {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #252525;
    margin-bottom: 16px;
    display: none; }
.close-filter {
    display: none;
    @media (max-width: 767px) {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        .icon-remove {
            position: static; } } }
.filter-btn-row {
    display: none;
    @media (max-width: 767px) {
        display: block;
        width: 100%; } }
.call-filter {
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: #a5a7a9;
    margin-bottom: 20px; }
.product-img-slide {
    .product-img {
        margin-bottom: 200px; }
    &.slick-slide {
        .product-img {
            margin-bottom: 0; } } }
