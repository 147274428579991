// footer
//-----------------------------------------------------------------------------------

.footer {
    background-color: #261737;
    padding-top: 69px;
    padding-bottom: 97px;
    @media (max-width: 767px) {
        padding: 40px 0; } }
.footer-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    @media (max-width: 767px) {
        flex-direction: column; } }
.footer-name {
    font-family: $Raleway;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 45px;
    text-transform: uppercase; }
.footer-column-name {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 10px; }
.footer-phone {
    display: flex;
    font-family: Arial;
    font-size: 24px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 20px;
    &:hover {
        text-decoration: none; } }
.work-time {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 7px;
    span {
        font-weight: bold;
        color: #fff; } }
.footer-mail {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    margin-top: 19px;
    margin-bottom: 7px;
    &:hover {
        text-decoration: none; } }
.footer-address {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; }
.footer-column {
    &.mod-first {
        margin-right: 162px;
        @media (max-width: 991px) {
            margin-right: 30px; }
        @media (max-width: 767px) {
            margin-top: 20px; } }
    &.mod-third {
        @media (max-width: 767px) {
            display: flex;
            flex-direction: column-reverse; } } }
.footer-column {
    &.mod-second {
        margin-right: 30px;
        margin-top: 68px;
        @media (max-width: 767px) {
            margin-top: 20px; } } }
.footer-link {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    transition: 0.3s;
    &:hover {
        text-decoration: none;
        color: #efb31a; } }
.social-link {
    display: flex;
    &:hover {
        text-decoration: none;
        .icon {
            color: #efb31a; } } }
.social-list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 -10px 47px;
    .icon {
        color: #fff;
        margin: 0 10px;
        transition: 0.3s; } }
.mobile-column {
    @media (max-width: 767px) {
        margin-top: 20px;
        margin-bottom: 10px; } }
.scroll-up {
    position: fixed;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    right: 40px;
    bottom: 40px;
    background-color: #5E3B8A;
    color: #fff;
    z-index: 100;
    .icon {
        color: #fff;
        transform: rotate(-90deg);
        @media (max-width: 767px) {
            width: 10px; } }
    @media (max-width: 767px) {
        width: 40px;
        height: 40px;
        right: 20px;
        bottom: 20px; } }
