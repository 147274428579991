// card product page
//-----------------------------------------------------------------------------------

.card {
    .breadcrumbs-item {
        margin-bottom: 10px; }
    .breadcrumbs {
        margin-bottom: 10px; } }
.card-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 47px;
    @media (max-width: 991px) {
        flex-direction: column;
        margin-bottom: 20px; } }
.product-name-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;
    @media (max-width: 767px) {
        margin-bottom: 20px;
        flex-direction: column;
        align-items: flex-start; } }
.product-name {
    font-family: $Raleway;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 0; }
.product-code {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 0;
    span {
        font-weight: normal; } }
.product-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: calc(100% - 235px);
    box-sizing: border-box;
    border-right: 1px solid #e7e7e7;
    @media (max-width: 991px) {
        width: 100%;
        border: 0;
        margin-bottom: 20px; }
    @media (max-width: 767px) {
        flex-direction: column; } }
.product-card-slider-block {
    width: 420px;
    @media (max-width: 767px) {
        margin-bottom: 20px;
        max-width: 100%; } }
.slick-initialized {
    .product-card-slider-item {
        display: flex; } }
.product-card-slider-item {
    flex-direction: row;
    justify-content: center;
    align-items: center; }
.product-card-img {
    max-height: 100%;
    display: block;
    margin: 0 auto; }
.product-card-slider {
    border-radius: 2px;
    box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.2);
    background-color: #ffffff;
    margin-bottom: 20px; }
.slick-initialized {
    .product-card-slider-nav-item {
        display: flex; } }
.product-card-slider-nav-item {
    margin: 4px 2px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 102px;
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid #fff;
    transition: 0.3s;
    &.slick-current {
        border-color: #efb31a; } }
.product-card-slider-nav {
    .slick-next {
        width: 14px;
        height: 24px;
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.3; } }
    .slick-prev {
        width: 14px;
        height: 24px;
        transition: 0.3s;
        .icon {
            transform: rotate(180deg); }
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.3; } } }
.product-card-info-block {
    width: 220px;
    margin-left: 30px;
    .product-item-row {
        margin-bottom: 16px; }
    .product-item-btn {
        opacity: 1;
        margin: 0 0 17px; }
    @media (max-width: 767px) {
        margin-left: 0;
        width: 420px;
        max-width: 100%; } }
.product-item-btn {
    &.mod-one {
        background-color: #fff;
        font-family: $Raleway;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        &:hover {
            color: #fff;
            background-color: #efb31a; } }
    &.mod-credit {
        background-color: #5e3b87;
        border-color: #5e3b87;
        font-weight: bold;
        margin-bottom: 57px;
        &:hover {
            color: #5e3b87;
            background-color: #fff; } } }
.bitcoin {
    margin-left: 30px; }
.product-card-lists-block {
    width: 235px;
    box-sizing: border-box;
    padding-left: 29px;
    @media (max-width: 991px) {
        padding-left: 0;
        width: 100%; } }
.card-list-name {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #222222;
    margin-bottom: 8px; }
.card-tabs {
    width: 795px;
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    @media (max-width: 991px) {
        width: 100%; } }
.card-tabs-head {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #f2f2f2;
    @media (max-width: 767px) {
        overflow: auto; } }
.card-tab-btn {
    height: 52px;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525;
    padding: 0 51px;
    transition: 0.3s;
    &.is-active {
        background-color: #fff; }
    @media (max-width: 991px) {
        padding: 0 39.4px; } }
.card-stat-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    border-bottom: 1px solid #f2f2f2;
    &:last-of-type {
        border: 0; } }
.card-stat-item {
    width: 50%;
    box-sizing: border-box;
    border-right: 1px solid #f2f2f2;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525;
    padding: 12px 12px 12px 60px;
    &:last-of-type {
        border: 0;
        font-weight: normal; }
    @media (max-width: 767px) {
        padding: 10px; } }
.card-tab {
    display: none;
    &.is-active {
        display: block; } }
.card-tab-description-block {
    width: 100%;
    box-sizing: border-box;
    padding: 35px 60px 70px 60px;
    @media (max-width: 767px) {
        padding: 10px; } }
.tab-description-global-name {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #10151a;
    margin-bottom: 0; }
.tab-description-name {
    margin-bottom: 0;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #10151a; }
.tab-description-text {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #10151a;
    margin-bottom: 17px; }
.card-tab-recalls-block {
    padding: 35px 60px 50px;
    .tab-description-global-name {
        margin-bottom: 30px; }
    .load-more {
        margin: 0 auto 0;
        text-align: center;
        display: flex;
        justify-content: center; }
    @media (max-width: 767px) {
        padding: 10px; } }
.recall-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px; }
.recall-name {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #10151a;
    margin-bottom: 0; }
.recall-date {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #cccccc;
    margin-bottom: 0; }
.recall-text {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #10151a;
    margin-bottom: 37px;
    &:last-child {
        margin-bottom: 0; } }
.card-tab-products-block {
    padding: 35px 45px 50px;
    .catalog-conteiner {
        width: 100%;
        padding: 0; }
    .load-more {
        margin-bottom: 0; }
    @media (max-width: 767px) {
        padding: 10px; } }
.select {
    &.products {
        margin-bottom: 53px;
        .ui.selection.dropdown {
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            margin-left: 15px;
            min-height: 22px;
            padding-right: 30px; }
        .ui.dropdown > .text {
            font-family: $Raleway;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #10151a; }
        .ui.selection.visible.dropdown>.text:not(.default) {
            font-weight: bold; } } }
.sentence {
    &.mod-card {
        @media (max-width: 767px) {
            padding-bottom: 40px; } } }
