// static page
//-----------------------------------------------------------------------------------

.static-section {
    padding-top: 27px;
    padding-bottom: 70px;
    @media (max-width: 767px) {
        margin-top: 50px; } }
.tabs-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
    @media (max-width: 767px) {
        display: none; } }
.tab-btn {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e4247;
    transition: 0.3s;
    margin: 0 45.4px;
    &:hover {
        color: #5e3b87; }
    &.is-active {
        color: #5e3b87; }
    &:first-of-type {
        margin-left: 0; }
    &:last-of-type {
        margin-right: 0; } }
h1 {
    font-family: $BebasNeueRegular;
    font-size: 120px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    margin-bottom: 21px;
    @media (max-width: 991px) {
        font-size: 80px;
        margin-bottom: 18px; }
    @media (max-width: 767px) {
        font-size: 40px;
        margin-bottom: 10px; } }
p {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #252525;
    margin-bottom: 7px;
    span {
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #101211; } }
h2 {
    font-family: $Raleway;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #10151a;
    text-transform: uppercase;
    margin-bottom: 20px; }
h3 {
    font-family: $Raleway;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 11px; }
h4 {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    margin-bottom: 20px; }
.img {
    margin-bottom: 20px; }
.list-style {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    margin-bottom: 20px;
    text-transform: none; }
.list {
    li {
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #101211;
        position: relative;
        padding-left: 28px;
        margin-bottom: 14px;
        &:before {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            border: solid 1px #e3e3e3;
            left: 0;
            top: 1px;
            border-radius: 50%;
            box-sizing: border-box; }
        &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #efb31a;
            border-radius: 50%;
            left: 3px;
            top: 4px; } } }
.table-section {
    background-color: #f9f9fa;
    padding: 70px 0; }
thead {
    td {
        background-color: #5e3b87;
        font-family: $Raleway;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #ffffff;
        height: 50px;
        line-height: normal;
        text-align: center;
        vertical-align: middle;
        &:first-of-type {
            width: 40%; }
        &:nth-of-type(2) {
            width: 40%; } } }
table {
    border-collapse: separate;
    border-spacing: 10px;
    width: 100%; }
.solo {
    td {
        height: 50px;
        line-height: normal;
        text-align: center;
        width: 100%;
        background-color: #e1e1e1;
        font-family: $Raleway;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #333333;
        box-shadow: none;
        vertical-align: middle; } }
tbody {
    td {
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        text-align: center;
        line-height: normal;
        font-family: Arial;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #101211;
        vertical-align: middle;
        height: 50px; } }
td {
    padding: 10px;
    box-sizing: border-box; }
.table-container {
    width: 100%;
    overflow: auto; }
.side-bar {
    float: right;
    width: 300px;
    box-sizing: border-box;
    border-left: 1px solid #e7e7e7;
    margin-bottom: 20px;
    @media (max-width: 767px) {
        display: none; } }
.side-bar-item {
    padding-left: 29px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 24px;
    padding-top: 25px;
    &:first-of-type {
        padding-top: 0; }
    &:last-of-type {
        border: 0; } }
.article-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
    &:last-child {
        margin-bottom: 0; } }
.article-item-name {
    ont-family: $Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #252525;
    display: block;
    margin-bottom: 7px;
    &:hover {
        text-decoration: none; } }
.article-item-info {
    margin-left: 15px;
    width: calc(100% - 70px); }
.article-item-info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
.article-item-date {
    font-family: $Raleway;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #cccccc;
    margin-bottom: 0; }
.article-item-link {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #222222;
    text-decoration: underline;
    &:hover {
        text-decoration: none; } }
