// index/home page
//-----------------------------------------------------------------------------------

.container {
  @media (min-width: 1230px) {
    width: 970px; } }
.first {
    @media (max-width: 767px) {
        margin-top: 50px; } }
.first-slider-item {
    position: relative;
    .container {
        position: relative;
        z-index: 2;
        height: 100%; }
    &.mod-laptop {
        background: linear-gradient(to right, rgba(84,52,123,0.2) 0%,rgba(255,255,255,1) 100%);
        .first-slider-name {
            font-size: 72px;
            margin-bottom: 0;
            @media (max-width: 767px) {
                font-size: 40px; } }
        .slider-logo-block {
            top: 410px; }
        .slide-img {
            &.mod-first {
                right: -220px;
                top: 40px;
                @media (max-width: 1080px) {
                    display: none; } }
            &.mod-second {
                right: auto;
                left: -150px;
                top: 70px;
                @media (max-width: 991px) {
                    display: none; } }
            &.mod-third {
                right: 555px;
                top: 55px;
                @media (max-width: 1700px) {
                    right: 415px; }
                @media (max-width: 1600px) {
                    right: 305px; }
                @media (max-width: 1300px) {
                    right: 255px; }
                @media (max-width: 1080px) {
                    right: 25px; }
                @media (max-width: 991px) {
                    top: 11px; }
                @media (max-width: 767px) {
                    top: 40px;
                    width: 200px;
                    right: 15px; }
                @media (max-width: 480px) {
                    display: none; } } } }
    &.mod-playstation {
        background: url(../img/playstation-bg.png) center center no-repeat;
        background-size: cover;
        .first-slider-name {
            font-size: 72px;
            @media (max-width: 767px) {
                font-size: 40px; } }
        .slider-logo-block {
            top: auto;
            left: auto;
            position: relative;
            margin-top: 59px;
            @media (max-width: 991px) {
                margin-top: 20px; } }
        .slide-img {
            &.mod-second {
                right: auto;
                left: -200px;
                top: -55px;
                @media (max-width: 767px) {
                    display: none; } }
            &.mod-third {
                right: 30px;
                top: 25px;
                @media (max-width: 1600px) {
                    right: 200px; }
                @media (max-width: 1330px) {
                    right: 40px; }
                @media (max-width: 991px) {
                    right: 5px;
                    top: 70px;
                    height: 230px; }
                @media (max-width: 767px) {
                    top: 22px;
                    height: 160px; }
                @media (max-width: 480px) {
                    display: none; } } } }
    &.mod-armchair {
        background: url(../img/armchair-bg.png) center center no-repeat;
        background-size: cover;
        .first-slider-container {
            padding-left: 240px;
            @media (max-width: 991px) {
                padding-left: 0; } }
        .first-slider-name {
            font-size: 72px;
            @media (max-width: 767px) {
                font-size: 40px; }
            span {
                background-color: #efb31a;
                padding: 10px 10px 0 10px;
                @media (max-width: 767px) {
                    padding: 5px 5px 0 5px; } } }
        .slide-img {
            &.mod-third {
                right: auto;
                top: 0;
                left: 0;
                @media (max-width: 1600px) {
                    left: -120px; }
                @media (max-width: 1300px) {
                    display: none; } }
            &.mod-first {
                right: auto;
                top: 10px;
                left: 320px;
                @media (max-width: 1600px) {
                    left: 200px; }
                @media (max-width: 1300px) {
                    left: 70px; }
                @media (max-width: 1100px) {
                    left: 10px; }
                @media (max-width: 991px) {
                    left: auto;
                    right: 10px;
                    height: 340px; }
                @media (max-width: 767px) {
                    height: 170px;
                    top: 15px; }
                @media (max-width: 480px) {
                    display: none; } }
            &.mod-second {
                max-height: none;
                top: -520px;
                right: -850px;
                max-width: none;
                @media (max-width: 1300px) {
                    display: none; } } }
        .slider-logo-block {
            top: 410px;
            left: 810px; } } }
.slide-img {
    max-height: 100%;
    position: absolute;
    right: 0; }
.first-slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    height: 100%; }
.slider-tag-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 47px;
    @media (max-width: 991px) {
        margin-bottom: 27px; }
    @media (max-width: 767px) {
        margin-bottom: 10px; } }
.first-slider-name {
    font-family: $BebasNeueBold;
    font-size: 120px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    margin-bottom: 28px;
    @media (max-width: 991px) {
        font-size: 80px;
        margin-bottom: 18px; }
    @media (max-width: 767px) {
        font-size: 40px;
        margin-bottom: 10px; } }
.slider-tag {
    font-family: $BebasNeueRegular;
    font-size: 36px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 45px;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    margin-bottom: 7px;
    border-radius: 4px;
    background-color: #040605;
    padding: 0 9px;
    &:last-of-type {
        margin-bottom: 0; }
    @media (max-width: 991px) {
        font-size: 25px; }
    @media (max-width: 767px) {
        line-height: 30px;
        font-size: 20px; } }
.slider-more-link {
    display: flex;
    line-height: 57px;
    border-left: 4px solid #efb31a;
    padding-left: 15px;
    font-family: $Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #040605;
    transition: 0.3s;
    &:hover {
        text-decoration: none;
        border-color: #5e3b87; }
    @media (max-width: 767px) {
        line-height: 30px; } }
.slider-logo-block {
    position: absolute;
    top: 237px;
    left: 299px;
    @media (max-width: 991px) {
        left: 190px;
        top: 160px; }
    @media (max-width: 767px) {
        display: none; } }
.slider-logo {
    width: 118px; }
.slider-logo-text {
    position: absolute;
    top: 0;
    right: 0;
    font-family: $Raleway;
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605; }
.first-slider {
    background-color: #f5f6f8;
    &.slick-dotted.slick-slider {
        margin-bottom: 0; }
    .slick-dots {
        bottom: 32px;
        @media (max-width: 767px) {
            bottom: 10px; }
        li {
            width: auto;
            height: auto;
            margin: 0 6px;
            button {
                width: 8px;
                height: 8px;
                border: solid 1px #5e3b87;
                background-color: #fff;
                transition: 0.3s;
                border-radius: 50%;
                padding: 0;
                box-sizing: border-box;
                &:before {
                    display: none; } }
            &.slick-active {
                button {
                    background-color: #5e3b87; } } } }
    .slick-prev {
        left: 50%;
        margin-left: -560px;
        z-index: 2;
        width: 14px;
        height: 24px;
        transform: rotate(180deg);
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.2; } }
    .slick-next {
        right: 50%;
        margin-right: -560px;
        z-index: 2;
        width: 14px;
        height: 24px;
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.2; } } }
.icon-slider-arrow {
    width: 14px;
    height: 24px;
    color: #10151a; }
.sentence, .exclusive, .recomded, .popular, .top-cost {
    position: relative;
    padding-top: 63px;
    overflow: hidden;
    @media (max-width: 767px) {
        padding-top: 40px; }
    .container {
        position: relative;
        z-index: 2; } }
.section-head-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px; } }
.section-name {
    font-family: $Raleway;
    font-size: 30px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #10151a;
    text-transform: uppercase;
    margin-bottom: 0; }
.gray-block {
    position: absolute;
    left: 50%;
    margin-left: -960px;
    height: 100%;
    width: 790px;
    background-color: #f9f9fa;
    top: 0;
    @media (max-width: 767px) {
        display: none; } }
.top-cost {
    .gray-block {
        position: absolute;
        right: 50%;
        left: auto;
        margin-right: 0;
        margin-right: -960px;
        height: 100%;
        width: 1130px;
        background-color: #f9f9fa;
        top: 0; } }
.product-slider {
    width: 100%;
    .slick-prev {
        left: 50%;
        margin-left: -560px;
        z-index: 2;
        width: 14px;
        height: 24px;
        transform: rotate(180deg);
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.2; } }
    .slick-next {
        right: 50%;
        margin-right: -560px;
        z-index: 2;
        width: 14px;
        height: 24px;
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.2; } }
    .slick-list {
        overflow: visible; } }
.product-slider-item {
    opacity: 0.05;
    &.slick-active {
        opacity: 1; }
    @media (max-width: 767px) {
        position: relative;
        z-index: 2;
        .product-item-conteiner {
            background-color: #fff;
            box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.2);
            margin: 0 -10px;
            width: 240px;
            padding: 0 10px 26px;
            @media (max-width: 767px) {
                box-shadow: none;
                margin: auto;
                width: 220px;
                padding: 0;
                .product-item-row {
                    padding: 0; }
                .product-item-name {
                    padding: 0; }
                .product-cost-row {
                    padding: 0; } } }
        .product-img-block {
            box-shadow: none; }
        .product-img-block {
            width: 100%; }
        .product-item-btn {
            opacity: 1; }
        .product-item-row {
            padding: 0 10px; }
        .product-item-name {
            padding: 0 10px; }
        .product-cost-row {
            padding: 0 10px; }
        &:hover {
            margin-top: 0 !important; } }
    &:hover {
        position: relative;
        z-index: 2;
        &:after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 20px; }
        .product-item-conteiner {
            background-color: #fff;
            box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.2);
            margin: -20px -10px 0;
            width: 240px;
            padding: 0 10px 26px;
            @media (max-width: 767px) {
                box-shadow: none;
                margin: auto;
                width: 220px;
                padding: 0;
                .product-item-row {
                    padding: 0; }
                .product-item-name {
                    padding: 0; }
                .product-cost-row {
                    padding: 0; } } }
        .product-img-block {
            box-shadow: none; }
        .product-img-block {
            width: 100%; }
        .product-item-btn {
            opacity: 1; }
        .product-item-row {
            padding: 0 10px; }
        .product-item-name {
            padding: 0 10px; }
        .product-cost-row {
            padding: 0 10px; } } }
.product-img-block {
    width: 220px;
    height: 220px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.1);
    margin-bottom: 20px;
    background-color: #fff;
    overflow: hidden; }
.product-item-conteiner {
    width: 220px;
    margin: 0 auto;
    padding-bottom: 26px; }
.look-more {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e3b87;
    text-decoration: underline;
    &:hover {
        text-decoration: none; }
    @media (max-width: 767px) {
        margin-top: 10px; } }
.product-item-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px; }
.icon-star {
    width: 12.7px;
    height: 12.3px;
    color: #a5a7a9;
    transition: 0.3s;
    &.is-active {
        color: #efb31a; } }
.product-item-recalls {
    font-family: Arial;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a5a7a9; }
.product-item-name {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #252525;
    padding-right: 6px;
    height: 44px;
    overflow: hidden;
    margin-bottom: 14px; }
.product-cost {
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #252525; }
.product-cost-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px; }
.icon-product-like {
    width: 20px;
    height: 18px;
    transition: 0.3s; }
.icon-product-comparison {
    width: 21px;
    height: 18px;
    transition: 0.3s; }
.product-item-like {
    color: #a5a7a9;
    margin-right: 13px;
    transition: 0.3s;
    &.is-active {
        color: #5e3b87; } }
.product-item-comparison {
    transition: 0.3s;
    color: #a5a7a9;
    &.is-active {
        color: #5e3b87; } }
.product-item-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 43px;
    opacity: 0;
    overflow: hidden;
    border-radius: 21.5px;
    background-color: #efb31a;
    transition: 0.3s;
    color: #fff;
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    width: 200px;
    margin: 0 auto;
    box-sizing: border-box;
    border: 2px solid #efb31a;
    &:hover {
        text-decoration: none;
        background-color: #fff;
        color: #efb31a; } }

.icon-product-basket {
    width: 20px;
    height: 19px;
    margin-right: 11px; }
.partners {
    padding-top: 63px;
    padding-bottom: 71px;
    .section-name {
        margin-bottom: 64px; }
    .content {
        @media (max-width: 991px) {
            flex-wrap: wrap;
            justify-content: center; } } }
.partners-img {
    transition: 0.3s;
    filter: grayscale(100%);
    opacity: 0.3;
    &:hover {
        filter: grayscale(0);
        opacity: 1; }
    @media (max-width: 991px) {
        margin: 10px; } }
.articles {
    padding-top: 63px;
    padding-bottom: 70px;
    background-color: #f9f9fa;
    @media (max-width: 767px) {
        padding-top: 40px;
        padding-bottom: 40px; } }
.articles-slider-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 10px;
    @media (max-width: 500px) {
        flex-direction: column; } }
.articles-slider-info-block {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 220px);
    @media (max-width: 500px) {
        width: 100%; } }
.articles-slider-date {
    font-family: Arial;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a5a7a9;
    margin-bottom: 10px; }
.articles-slider-name {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    margin-bottom: 8px;
    &:hover {
        text-decoration: none; } }
.articles-slider-text {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #101211;
    margin-bottom: 22px; }
.articles-slider {
    .slick-list {
        margin: 0 -10px; }
    .slick-prev {
        left: 50%;
        margin-left: -560px;
        z-index: 2;
        width: 14px;
        height: 24px;
        transform: rotate(180deg);
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.2; } }
    .slick-next {
        right: 50%;
        margin-right: -560px;
        z-index: 2;
        width: 14px;
        height: 24px;
        transition: 0.3s;
        &:before {
            display: none; }
        &.slick-disabled {
            opacity: 0.2; } }
    .slick-dots {
        bottom: -79px;
        @media (max-width: 767px) {
            bottom: -40px; }
        li {
            width: auto;
            height: auto;
            margin: 0 6px;
            button {
                width: 8px;
                height: 8px;
                border: solid 1px #5e3b87;
                background-color: #fff;
                transition: 0.3s;
                border-radius: 50%;
                padding: 0;
                box-sizing: border-box;
                &:before {
                    display: none; } }
            &.slick-active {
                button {
                    background-color: #5e3b87; } } } } }
.icon-article-arrow {
    width: 9px;
    height: 7px;
    margin-left: 13px; }
.articles-slider-more {
    font-family: $Raleway;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5e3b87;
    display: block;
    margin-left: auto;
    &:hover {
        text-decoration: none; } }
.subscribe {
    background-color: #36214e;
    padding: 52px 0;
    @media (max-width: 767px) {
        padding: 40px 0;
        .content {
            display: flex;
            flex-direction: column;
            align-items: center; }
        .section-name {
            margin-bottom: 20px;
            text-align: center; } }
    .content {
        width: 800px;
        margin: 0 auto;
        max-width: 100%; }
    .section-name {
        color: #fff; } }
.subscribe-btn {
    font-family: $Raleway;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 54px;
    letter-spacing: normal;
    color: #ffffff;
    width: 227px;
    height: 58px;
    border-radius: 29px;
    background-color: #efb31a;
    box-sizing: border-box;
    border: 2px solid #efb31a;
    text-align: center;
    transition: 0.3s;
    &:hover {
        background-color: #ffffff;
        color: #efb31a;
        text-decoration: none; } }
.articles-slider-img {
    @media (max-width: 500px) {
        width: 100%; } }
