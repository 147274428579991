.icon {
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;
	fill: currentColor; }

.icon-article-arrow {
	width:(9/7)*1em {} }

.icon-basket {
	width:(32/31)*1em {} }

.icon-catalog-dots {
	width:(13/13)*1em {} }

.icon-catalog-list {
	width:(13/13)*1em {} }

.icon-comparison {
	width:(37/29)*1em {} }

.icon-f {
	width:(10/18)*1em {} }

.icon-filter {
	width:(402.577/402.577)*1em {} }

.icon-g {
	width:(17/17)*1em {} }

.icon-i {
	width:(17/17)*1em {} }

.icon-like {
	width:(33/29)*1em {} }

.icon-menu {
	width:(53/53)*1em {} }

.icon-phone-arrow {
	width:(7/4)*1em {} }

.icon-product-basket {
	width:(20/20)*1em {} }

.icon-product-comparison {
	width:(21/18)*1em {} }

.icon-product-like {
	width:(20/18)*1em {} }

.icon-remove {
	width:(6/6)*1em {} }

.icon-search {
	width:(16/16)*1em {} }

.icon-slider-arrow {
	width:(14/24)*1em {} }

.icon-star {
	width:(14/13)*1em {} }

.icon-t {
	width:(19/17)*1em {} }

.icon-tw {
	width:(21/17)*1em {} }

.icon-user {
	width:(12/16)*1em {} }

.icon-vk {
	width:(30/19)*1em {} }

