// category page
//-----------------------------------------------------------------------------------

.page-banner {
    height: 200px;
    background: url(../img/slide-bg.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 45px;
    box-sizing: border-box;
    @media (max-width: 767px) {
        margin-top: 50px;
        margin: 50px -15px 0;
        height: 130px; }
    .first-slider-name {
        padding-left: 58px;
        font-family: $BebasNeueRegular;
        font-weight: normal;
        @media (max-width: 767px) {
            padding-left: 15px;
            font-size: 33px; } } }
.category-section {
    padding: 25px 0 70px;
    @media (max-width: 767px) {
        padding-bottom: 40px; } }
.breadcrumbs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 22px;
    flex-wrap: wrap;
    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start; } }
.breadcrumbs-item {
    display: block;
    font-family: $Raleway;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3e4247;
    margin: 0 45.4px;
    position: relative;
    transition: 0.3s;
    &:hover {
        text-decoration: none; }
    &:first-child {
        margin-left: 0; }
    &:last-child {
        margin-right: 0; }
    &:before {
        content: '';
        position: absolute;
        left: calc(100% + 45px);
        top: 3.5px;
        background: url(../img/svg/breadcrumbs-arrow.svg) center center no-repeat;
        width: 5px;
        height: 9px; }
    &.mod-this {
        color: #5e3b87;
        &:before {
            display: none; } }
    @media (max-width: 767px) {
        margin: 5px 0;
        &:before {
            left: calc(100% + 5px); } } }
.category-item {
    display: block;
    margin: 0 10px;
    width: 220px;
    &:hover {
        text-decoration: none;
        .category-img-block {
            transform: translateY(10px); } } }
.category-img-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 220px;
    box-shadow: 0 0 20px 0 rgba(67, 73, 79, 0.1);
    background-color: #ffffff;
    margin-bottom: 26px;
    transition: 0.3s; }
.category-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px; }
.category-name {
    font-family: $Raleway;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #040605;
    text-align: center; }
