// used in structure-sidebars.less, structure-grid.less and structure-grid-px.less
//-----------------------------------------------------------------------------------
$widthContent: 960px;
$widthPageMax: none;
$widthPageMin: 0;

html {
  height: 100%; }

body {
  .no-flexbox & {
    min-height: 100%;
    height: 100%;
    display: block !important; } }

.page-wrapper,
.footer {
  max-width: $widthPageMax;
  min-width: $widthPageMin;
  width: 100%;
  margin: 0 auto;
  .no-flexbox & {
    width: auto; } }

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;

  .lte-ie-11 &,
  .lt-ie-11 & {
    min-height: 0; }

  .no-flexbox & {
    display: block !important;
    width: auto;
    min-height: 100%;
    height: auto !important;
    height: 100%; } }

.page-body {
  flex: 1;
  &.flex-none {
    flex: none; } }

.wrapper {
  position: relative; }
